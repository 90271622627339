import React from "react";
import Header from "../components/Header";
import Slider from "../components/Slider";
import About from "../components/About";
import Service from "../components/Service";
import Portfolio from "../components/Portfolio";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <div className="home-dark">
            <Header />
            <Slider />

            <section id="about" className="section about-section after-left-section">
                <div className="container">
                    <About />
                </div>
            </section>

            <section id="services" className="section services-section gray-bg">
                <div className="container">
                    <div className="row justify-content-center section-title text-center">
                        <div className="col-lg-7">
                            <h3 className="font-alt">My Services</h3>
                            <p>
                                We design and develop website or app for customers of all sizes,
                                specializing in creating stylish, modern websites, web services
                                and online stores.
                            </p>
                        </div>
                    </div>
                    <Service />
                </div>
            </section>

            <section id="work" className="section gray-bg">
                <div className="container">
                    <div className="row justify-content-center section-title text-center">
                        <div className="col-lg-7">
                            <h3 className="font-alt">Our Portfolio</h3>
                            <p>
                                We design and develop services for customers of all sizes,
                                specializing in creating stylish, modern websites, web services
                                and online stores.
                            </p>
                        </div>
                    </div>
                    <Portfolio />
                </div>
            </section>

            <section id="contact" className="section after-left-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 my-3">
                            <div className="contct-form">
                                <img src="/img/contact-us.jpg" alt="Company Profile" />
                            </div>
                        </div>

                        <div className="col-lg-4 ml-auto my-3">
                            <div className="contact-info">
                                <div className="sm-title">
                                    <h4 className="font-alt">Get in touch</h4>
                                    <p>
                                        Always available for freelancing if the right project comes
                                        along, Feel free to contact us.
                                    </p>
                                </div>
                                <div className="media">
                                    <div className="icon">
                                        <i className="ti-map"></i>
                                    </div>
                                    <span className="media-body">
                                        Jakarta, Indonesia.
                                    </span>
                                </div>

                                <div className="media">
                                    <div className="icon">
                                        <i className="ti-email"></i>
                                    </div>
                                    <span className="media-body">
                                        elfazamuhammad1@gmail.com
                                    </span>
                                </div>

                                <div className="media">
                                    <div className="icon">
                                        <i className="ti-mobile"></i>
                                    </div>
                                    <span className="media-body">
                                        +62 812 1919 8307
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer white">
                <div className="container">
                    <Footer />
                </div>
            </footer>
        </div>
    );
};

export default Home;
