import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-3 image-logo-about">
          <div className="row align-items-center justify-content-center img-box dark-img-box" >
            <img src="img/about/mef-dev.webp" alt="mef-dev"/>
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h3>MEF Development</h3>
            <h5>
              A <span className="color-theme">Web Developer</span> based in{" "}
              <span className="color-theme">Indonesia</span>
            </h5>
            <p>
              We design and develop services for customers of all sizes,
              specializing in creating stylish, modern websites, web services
              and online stores. My passion is to design digital user
              experiences through the bold interface and meaningful
              interactions. Check out our Portfolio
            </p>
            <div className="row about-list">
              <div className="col-md-12">
                <div className="media">
                  <label>E-mail</label>
                  <p>elfazamuhammad1@gmail.com</p>
                </div>
                <div className="media">
                  <label>Phone</label>
                  <p>+62-812-1919-8307</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            {/* <div className="btn-bar">
                            <a className="px-btn px-btn-theme" href="img/resume.png" download>
                                Download CV
                            </a>
                        </div> */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
